import React, { Component } from 'react';
import NavigationBase from './../components/navigationBase.jsx';

class NavigationPhysio extends Component {



    render() {
        return (
          <div className="row" id="navigation">
            <NavigationBase expand={"lg"}/>
      </div>
        )
    }
}

export default NavigationPhysio;