import React, { Component } from 'react';

class Teasing extends Component {
    render() {      
          return (
              <div id="teasing" className="pt-3">
            <div className="row justify-content-center">
            <div className="col-9">
            <p>
            Mit Leidenschaft und Kompetenz helfe ich Ihnen als Physiotherapeut, Trainer und Coach. Der Mensch mit seinem Problem wird ganzheitlich betrachtet. Die Ursachen zu suchen, realistische Ziele und Lösungswege zu finden, stehen im Mittelpunkt meiner Arbeit.
            </p>
            <p>
            Als kassen-zugelassener Physiotherapeut therapiere ich nur auf Rezeptbasis (Kassenrezepte und Privatrezepte). Auf freier Basis biete ich zudem funktionelles und neurozentrales Training an.
            </p>
            <p>
            Meine Praxis für Physiotherapie liegt nahe der Innenstadt von Neumünster. Ich freue mich, Sie hier begrüßen zu dürfen.
            </p>
            </div>
            </div>
              </div>
          );
        }
}
export default Teasing;