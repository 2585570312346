import React, { Component } from 'react';
import logo from './../images/logoBIG.png';
import Navigation from './navigationBase';
import Modal from 'react-responsive-modal';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasecurity : false,
            impressum: false
        }
    }
    
    state = {
        impressum: false,
        datasecurity: false,
    };

    onOpenImpressumModal = (title) => {
        this.setState({ impressum: true, datasecurity: false });
      };

    onOpenDataModal = (title) => {
    this.setState({ impressum: false, datasecurity: true });
    };

    onCloseModal = () => {
    this.setState({ impressum: false, datasecurity: false });
    };


    render() {
        const  state  = this.state;
        return (
            <div>
            <div className="container-fluid" id="footer">
            <div className="row justify-content-center greencontainer">
            <div className="col-9 row pt-5 pb-5">
            <div className="col-12 col-md-4 col-lg-4 col-xl-4 smpt5">
            <img src={logo} alt="logo"/>
            <div className="pt-4">
            Jens Winter<br/>
            Altonaer Straße 86<br/>
            24534 Neumünster<br/>
            Telefon 04321 690 78 66<br/>
            Fax 04321 6907867<br/>
            E-Mail praxis@physio-logik-nms.de<br/>
            </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4 col-xl-4 smpt5">
            <h5>
            Physiotherapeut in Neumünster
            </h5>
            <div className="pt-4">
            Die Praxis für Physiotherapie liegt in der Altonaer Straße 86 in Neumünster. Nahe der Innenstadt und gut zu erreichen von Wittort, Padens- tedt-Kamp, Gartenstadt, Faldera und anderen Stadtteilen Neumünsters. Ich behandle sowohl Kassen- als auch Privatpatienten in klassischer Physiotherapie und Manualtherapie
            </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4 col-xl-4 smpt5">
            <div>
            <Navigation vertical={true} expand={"xs"}/>  
            </div>
            <div className="pt-4">
            <div onClick={() => this.onOpenDataModal('Datenschutz')}><span className="link">Datenschutzerklärung</span></div>
            <div onClick={() => this.onOpenImpressumModal('Impressum')}><span className="link">Impressum</span></div>
            <br/>
            Eine Website mit GUTEN IDEEN von <br/><a href="http://www.behrendt-kommunikation.de" rel="noopener noreferrer" target="_blank">behrendt-kommunikation.de </a> <br/>Fotografie: Arndt Behrendt / <a href="http://shutterstock.de" target="_blank" rel="noopener noreferrer"> Shutterstuck.de</a>
            </div>
            </div>
            </div>
            </div>
     
        <div>
        <Modal closeIconSize={50} animationDuration={0} open={state.datasecurity} onClose={this.onCloseModal}>
        <div id="viewMod" >
        <div className="PopUpContainer row flex-justify-center">
        <h1 className="col-10">Datenschutzerklärung</h1>
        </div>
        <div className="row flex-justify-center">
        <div className="PopUpContainer col-10 flex-justify-center">
        <div>               
            <h2> Allgemeiner Hinweis und Pflichtinformationen </h2>
            <h2>Benennung der verantwortlichen Stelle</h2>
            <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
            Physio Logik <br/> 
            Jens Winter <br/> 
            Altonaer Straße 86 <br/>
            24534 Neumünster <br/> <br/>
            <p>
            Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>

            <h2> Widerruf Ihrer Einwilligung zur Datenverarbeitung </h2>
            <p>
            Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
            <h2>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h2>
            <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"> https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
            <h2> Recht auf Datenübertragbarkeit </h2>
            <p> Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist. </p>
            <h2> Recht auf Auskunft, Berichtigung, Sperrung, Löschung </h2>
            <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
            <h2> SSL- bzw. TLS-Verschlüsselung </h2>
            <p> Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile. </p>
            <h2> Google Web Fonts </h2>
            <p>
            Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
            Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.
            Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.
            Einzelheiten über Google Web Fonts finden Sie unter: <a href="https://www.google.com/fonts#AboutPlace:about"> https://www.google.com/fonts#AboutPlace:about </a> und weitere Informationen in den Datenschutzbestimmungen von Google: <a href="https://policies.google.com/privacy/partners?hl=de"> https://policies.google.com/privacy/partners?hl=de </a></p>
            <p>Quelle: Datenschutz-Konfigurator von <a href="mein-datenschutzbeauftragter.de"> mein-datenschutzbeauftragter.de </a></p>
        </div>
        </div>
        </div>
        </div>
        </Modal>

        <Modal closeIconSize={50} animationDuration={0} open={state.impressum} onClose={this.onCloseModal}>
        <div id="viewMod" >
        <div className="PopUpContainer row flex-justify-center">
        <h1 className="col-10">Impressum</h1>
        </div>
        <div className="row flex-justify-center">
        <div className="PopUpContainer col-10 flex-justify-center">
        <div>               
            <h2> Angaben gemäß § 5 TMG </h2>
            Physio Logik <br/> 
            Jens Winter <br/> 
            Altonaer Straße 86 <br/>
            24534 Neumünster <br/> <br/>
       
            <h2> Kontakt </h2>
            Telefon: 04321 690 78 66 <br/> 
            Telefax: 04321 690 78 67 <br/> 
            E-Mail: praxis@physio-logik-nms.de <br/> 
            <br/>
            <h2> Aufsichtsbehörde </h2>
            Gesundheitsamt Neumünster <br/>
            Meßtorffweg 8 <br/>
            24534 Neumünster <br/>
            <a href="http://www.neumuenster.de/gesellschaft-soziales/gesundheit/abteilungen/" rel="noopener noreferrer" target="_blank">http://www.neumuenster.de/gesellschaft-soziales/gesundheit/abteilungen/</a> <br/>
            <br/>
            <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>

            Berufsbezeichnung: Staatl. anerkannter Physiotherapeut <br/>
            Verliehen durch: Land Schleswig-Holstein <br/>
            Es gelten folgende berufsrechtliche Regelungen:  <br/>
            Gesetz über die Berufe in der Physiotherapie (Masseur- und Physiotherapeutengesetz - MPhG)   <br/>
            Regelungen einsehbar unter:  <br/>
            <a href="http://www.gesetze-im-internet.de/mphg/BJNR108400994.html" rel="noopener noreferrer" target="_blank">http://www.gesetze-im-internet.de/mphg/BJNR108400994.html</a>
            <br/><br/>
            <h2>Verbraucherstreitbeilegung/Universalschlichtungsstelle </h2>
            
            Wir nehmen nicht an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. <br/>
            <br/>
            Aktuelle Fassung: Juli 2020<br/>
            Quelle: Impressum-Konfigurator von <a href="https://www.e-recht24.de" rel="noopener noreferrer" target="_blank">e-recht24.de</a>         
      
            
        </div>
        </div>
        </div>
        </div>
        </Modal>
        </div>
        </div>
        </div>
        )
    }
}

export default Footer;