import React, { Component } from 'react';
import './css/header.css';
import './css/footer.css';
import './css/content.css';
import './css/navigation.css';
import './css/slider.css';
import './css/teasing.css';
import Header from './components/header.jsx';
import NavigationPhysio from './components/navigationPhysio';
import Slider from './components/slider.jsx';
import TeasingContent from './components/teasing.jsx';
import Content from './components/content.jsx';
import Footer from './components/footer.jsx';

class App extends Component {

handleOnScroll = () => {
    var el = document.querySelector("#navigation");
    if(window.scrollY > el.offsetTop){
      el.classList.add("navbar-fix-top");
    }
    else{
    el.classList.remove("navbar-fix-top");
    } 
}


componentDidMount() {
 // window.addEventListener("scroll", this.handleOnScroll);
  document.title = "Physio Logik";
}

componentWillUnmount() {
 // window.removeEventListener("scroll", this.handleOnScroll);
}

  render() {
    return (
      <div>
      <div id="navi">
        <NavigationPhysio/>
        </div>
      <div id="App">
       <Header/>
       <Slider/>
       <TeasingContent/>
       <Content/>
       <Footer/>
      </div>
      </div>
    );
  }
}

export default App;
