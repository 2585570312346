import React, { Component } from 'react';
import header from './../images/bambus.jpg';
import hakimg from './../images/hakimg.png';
import bihimg from './../images/bihimg.png';
import plan from './../images/plan.png';
import waveleft from './../images/wave-left2.png';
import waveright from './../images/wave-rightw.png';
import wavemiddle from './../images/wave-middle.png';
import balance from './../images/balance.png';
import cert1 from './../images/1_Jens_Winter_Zertifikate.jpg';
import cert2 from './../images/2_Jens_Winter_Zertifikate.jpg';
import cert3 from './../images/3_Jens_Winter_Zertifikate.jpg';
import cert4 from './../images/4_Jens_Winter_Zertifikate.jpg';
import cert5 from './../images/5_Jens_Winter_Zertifikate.jpg';
import cert6 from './../images/6_Jens_Winter_Zertifikate.jpg';
import plpic from './../images/plpic.png';
import telefon from './../images/telefon.png';
import appointment from './../images/appointment.png';
import greencircle from './../images/greencircle.png';
import whitecircle from './../images/whitecircle.png';

class Content extends Component {

    updateDimensions() {
        var navi = document.getElementById('navi');
        var background = document.getElementById('background');
        background.style.height = navi.offsetHeight+'px';
        navi.style.top = background.offsetTop+'px';
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }


    render() {
        const divStyle = {
            backgroundImage: 'url('+header+')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          };
        return (
            <div className="co<div>ntainer-fluid" id="content">
            <div>
               <hr />
            <div className="row justify-content-center pb-5"  id="advantages">
            <div className="col-9">
            <h3>￼Ihre Vorteile</h3>
            </div>
            <div className="row col-9 nopad">
              <div className="col-12 col-lg-4">
              <div className="waves">
              <img src={waveleft} alt="wave" className="waveimg"/>
              <div className="wavetext">Ganzheitlichkeit</div>
              </div>
              <div className="row pt-3 pb-3">
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">von Kopf bis Fuß</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">auf allen Ebenen</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">mit allen Sinnen</div>
              </div>
              </div>
              <div className="col-12 col-lg-4">
              <div className="waves">
              <img src={wavemiddle} alt="wave" className="waveimg"/>
              <div className="wavetext">Fundierte Ausbildung</div>
              </div>
              <div className="row pt-3 pb-3">
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">alle Leistungen, die ich Ihnen anbiete basieren auf intensiven, international anerkannten Zertifikats-Ausbildungen.</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">kontinuierliche Weiterbildungen sind für mich selbstverständlich </div>
              </div>
              </div>
              <div className="col-12 col-lg-4">
              <div className="waves">
              <img src={waveright} alt="wave" className="waveimg"/>
              <div className="wavetext">Raum</div>
              </div>
              <div className="row pt-3 pb-3">
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">Befundung, Analyse </div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">Aufklärung, Beratung und Planung</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">effektive Behandlung</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">Körperwahrnehmung</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">Bewegung</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">Prozesse</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">Entwicklung</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">Gedanken</div>
              <div className="col-3 col-sm-1 nopad"><img src={greencircle} alt="circle" className="greencircle"/></div>
              <div className="col-9 col-sm-11 nopad">offene Worte</div>
              </div>
              </div>
            </div>
            <div className="row col-9 pictext  pt-5 pb-5" id="switch">
            <div className="col-12 col-lg-8">
            <img src={balance} alt="balance"></img>
            </div>
            <div className="col-12 col-lg-4 row justify-content-center bapicont" id="balancediv">
            <div className="col-7 col-md-9 col-lg-9 col-xl-7">
            <p>
            Das Gewebe vergisst nicht. Deswegen achte
            ich auf eine sanfte Behandlung, die den Körper als Ganzes respektiert. “
            </p>
            
            <span>Jens Winter, Physiotherapeut</span>            
            </div> 
            </div>

            </div>
            </div>
              </div>
            <div className="row">
                <div className="col-12 row justify-content-center pt-5 pb-5" id="training" style={divStyle}>
                <div className="col-9 row box">
                    <div className="col-12 col-lg-4 col-xl-4">
                    <img className="roundimg" src={hakimg} alt="bild1"/>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-8 greenbox">
                    <h3>Therapie & Training</h3>
                    <h4>ausgerichtet werden, ausgerichtet bleiben</h4>
                    <div className="row">
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Manuelle Therapie</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Krankengymnastik</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Lymphdrainage (postoperativ – orthopädisch)</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Funktionelles Training</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> Neurozentrales Training (Brain Gym, Touch for Health, Brain Fit)  </div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Mentales Training </div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Sportphysiotherapie </div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Gesundheitscoaching</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> Spezialgebiet: Kopf, Kiefer und Halswirbelsäule </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-12 row justify-content-center pt-5 pb-5" id="symptoms">
                <div className="col-9 row box">
                    <div className="col-12 col-lg-4 col-xl-4">
                    <img className="roundimg" src={bihimg} alt="bild1"/>
                    </div>
                    <div className="col-8 col-12 col-lg-8 col-xl-8 greenbox">
                    <h3>Symptom-Schwerpunkte</h3>
                    <h4>fit werden, fit bleiben</h4>
                    <div className="row">
                    <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Kopfschmerzen</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Kieferprobleme</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Nackenschmerzen</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> Rückenschmerzen</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Gelenkprobleme</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Sportverletzungen</div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad">Funktionseinschränkungen </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-12 row justify-content-center pt-5 pb-5" id="gesundheitsabc" style={divStyle}>
                <div className="col-9 row box">
                    <div className="col-12 col-lg-4 col-xl-4">
                    <img className="roundimg" src={plan} alt="bild1"/>
                    </div>
                    <div className="col-8 col-12 col-lg-8 col-xl-8 greenbox">
                    <h3>Ihre Gesundheit</h3>
                    <h4>bewusst werden, bewusst bleiben</h4>
                    <div className="row">
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> sich Zeit nehmen </div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> Entspannen </div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> Reflektieren </div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> innere Ausrichtung auf Ziel </div>
                        <div className="col-3 col-sm-1 nopad"><img src={whitecircle} alt="circle" className="greencircle"/></div>
                        <div className="col-9 col-sm-11 nopad"> professionelle Begleitung </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="row justify-content-center pt-5 pb-5" id="uebermich">
            <div className="col-9" >
            <h1>￼Ihr Physiotherapeut Jens Winter</h1>
                <p>
                Geboren, aufgewachsen und wohnhaft in Neumünster bin ich als Therapeut angetreten, den Menschen dieser Stadt etwas Besonderes anzubieten. Ich entsage dem Behandeln am Fließband und wende mich mehr meinen Patienten zu. 
                </p>
                <p>
                Der Focus meiner Arbeit ist das Therapieren, charakterisiert durch gegenseitigen Respekt, Konzentration auf Körper-Reaktion und Ruhe, aber auch offene Worte und gute Gespräche.
                </p>
            </div>
            </div>
            <div className="row justify-content-center pt-5 pb-5 greencontainer" id="qualifications">
            <div className="col-9">
            <h1>Qualifikationen</h1>
                <h2>
                So vielfältig wie Ihr Körper, so vielfältig sind meine Qualifikationen als Physiotherapeut.“
                </h2>
                <p>
                Ausbildung und Weiterbildung sind die Grundlage für eine erfolgreiche Behandlung. Schließlich geht es um Ihren Körper und Ihre Gesundheit. Neben einer klassischen Ausbildung als Sportphysiotherapeut und Manualtherapeut mit diversen Zusatzqualifikationen erwarb ich vielfältige weitere Qualifikationen beispielsweise als CRAFTA-Therapeut, Therapeut für „Manuelle Lymphdrainage“, PNF-Therapeut sowie Kinesio-Tape-Therapeut. Ich bin ausgebildeter Rückenschullehrer mit KddR-Lizenz und ärztlich geprüfter Spezialist in der komplexen physikalischen Kopfschmerztherapie. Der Blick über den Tellerrand ist mir immer wichtig.
                </p>
                <div className="thumbs">
                <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 pt-3"><img src={cert1} alt="zertifikat"/></div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 pt-3"><img src={cert2} alt="zertifikat"/></div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 pt-3"><img src={cert3} alt="zertifikat"/></div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 pt-3"><img src={cert4} alt="zertifikat"/></div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 pt-3"><img src={cert5} alt="zertifikat"/></div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-2 pt-3"><img src={cert6} alt="zertifikat"/></div>
                </div>
                </div>
            </div>
            </div>
            <div className="row justify-content-center pt-5 pb-5">
            <div className="col-9" id="cmd">
                <div className="row justify-content-center">
                <div className="col-12 flexcenter col-md-4 col-lg-4 col-xl-4">
                <img id="plpic" src={plpic} alt="cmd"/>
                </div>
                <div className="col-12 col-md-8 col-lg-8 col-xl-8 lgpt5">
                <h1>CMD: der Biss entscheidet!</h1>
                <h2>Kopf- & Kiefergelenk-Behandlung (CMD)</h2>
                <p>
                Kopf- und Kieferschmerzen, Rücken- und Nackenschmerzen sowie Zähneknirschen und sogar Schwindel haben häufig ihre Ursache in einer sogenannten Craniomandibuläre-Dysfunktion (CMD), das sind funktionelle Probleme im Bereich der Kiefergelenke.
                Mein gezieltes Training und meine Physiotherapie können bei einer solchen Dysfunktion eine echte Besserung der Beschwerden erzielen und Defizite im Kiefer sogar wieder beheben. Wichtig für Sie
                zu wissen: Bei einer CMD-Behandlung ist
                eine interdisziplinäre Zusammenarbeit mit Zahnärzten, Kieferorthopäden, Kieferchirurgen, Zahntechnikern und in manchen Fällen auch praktischen Ärzten unabdingbar. Sprechen Sie Ihren Zahnarzt oder Hausarzt an und fragen Sie nach einer physiotherapeutischen CMD-Therapie.
                </p>
                </div>
                </div>
            </div>
            </div>
            <div className="row justify-content-center pt-5 pb-5 greencontainer">
            <div className="col-9" id="contact">
            <div className="row justify-content-start">
                <div className="col-1">
                <img src={telefon} alt="telefon" id="telefon"/>
                </div>
                <div className="col-11">
                <h1>Vereinbaren Sie einen Termin</h1>
                <h2>04321 - 690 78 66</h2>
                </div>
                <div className="col-12">
                <div>
                Wir freuen uns auf Ihren Anruf. Wenn wir in der Behandlung sind nutzen Sie bitte den Anrufbeantworter und wir rufen am Nachmittag zurück.
                </div>
                </div>
            </div>
            </div>
            </div>
            <img src={appointment} alt="appointment" id="appointment"/>
            <div className="row justify-content-center pt-5 pb-5">
                <div className="col-9">
                <h1>Unsere Öffnungszeiten</h1>
                <div>
                    Montag - Donnerstag 8.00 - 12.30 Uhr und 14.00 - 19.00 Uhr 
                </div>
                <div>
                Freitag 8.00- 12.30 Uhr und 13.30 - 16.00 Uhr
                </div>
                <div>
    Die Praxis befindet sich in der Innenstadt von Neumünster in der Altonaer Straße 86a (gegenüber vom Netto-Markt)
    </div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2341.5575916974785!2d9.982436515485398!3d54.06385442842557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b24bccae96ce9f%3A0x10250ab443754948!2sHerr+Jens+Winter!5e0!3m2!1sde!2sde!4v1541244721064" width="100%" height="450" frameBorder="0" title="map" allowFullScreen></iframe>
        </div>
        )
    }
}

export default Content;