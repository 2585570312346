import React, { Component } from 'react';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

class NavigationBase extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }


  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
    componentDidMount() {
        Events.scrollEvent.register('begin', function(to, element) {
        });
     
        Events.scrollEvent.register('end', function(to, element) {
        });
        scrollSpy.update();
      }

      componentWillUnmount () {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
      }

     navigateToSection(destination) {
        var section = document.getElementById(destination);
        scroll.scrollTo(section.offsetTop);
      }
        

    render() {
      var vertical = this.props.vertical;
        return (
            <Navbar light expand={this.props.expand}>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav vertical={vertical} navbar>
              <NavItem>      
              <NavLink onClick={() => this.navigateToSection('advantages')}> Ihre Vorteile </NavLink>
              </NavItem>
              <NavItem>
              <NavLink onClick={() => this.navigateToSection('training')}> Therapie & Training </NavLink>
              </NavItem>
              <NavItem>
              <NavLink onClick={() => this.navigateToSection('symptoms')}> Symptom-Schwerpunkte </NavLink>
              </NavItem>
              <NavItem>
              <NavLink onClick={() => this.navigateToSection('gesundheitsabc')}> Ihre Gesundheit </NavLink>
              </NavItem>
              <NavItem>
              <NavLink onClick={() => this.navigateToSection('uebermich')}> Jens Winter </NavLink>
              </NavItem>
              <NavItem>
              <NavLink onClick={() => this.navigateToSection('qualifications')}> Qualifikationen </NavLink>
              </NavItem>
              <NavItem>
              <NavLink onClick={() => this.navigateToSection('cmd')}> CMD </NavLink>
              </NavItem>
              <NavItem>
              <NavLink onClick={() => this.navigateToSection('contact')}> Kontakt </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        )
    }
}

export default NavigationBase;