import React, { Component } from 'react';
import './../css/slider.css';
import slider1 from './../images/slider1.png';
import slider2 from './../images/slider2.png';
import slider3 from './../images/slider3.png';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class Slider extends Component {
    render() {      
          return (
              <div id="slider" className="pt-3">
                        <Carousel showThumbs={true} stopOnHover={false} interval={10000} autoPlay={true} infiniteLoop={true} showStatus={false}>
                <div>
                    <img src={slider1} alt="slider1"/>
                    <div className="caption">
                    <div>Zurück zu neuer Leichtigkeit</div>
                    <div>Erlangen Sie mehr Flexibilität durch kompetente</div>
                    <div>Gelenk-, Wirbelsäulen- und Faszientherapie</div>
                    </div>
                </div>
                <div>
                    <img src={slider2} alt="slider2"/>
                    <div className="caption">
                    <div>Sanfte Schritte</div>
                    <div>Gehen Sie den natürlichen Weg</div>
                    <div>der Behandlung mit ganzheitlicher</div>
                    <div>manueller Therapie</div>
                    </div>
                </div>
                <div>
                    <img src={slider3} alt="slider3"/>
                    <div className="caption">
                    <div>In Bewegung kommen<br/>In Bewegung bleiben</div>
                    <div className="pt-1">Gewinnen Sie mit funktionellem Training</div>
                    <div>neue Stabilität und Dynamik</div>
                    </div>
                </div>
            </Carousel>
            </div>
          );
        }
}

export default Slider;