import React, { Component } from 'react';
import header from './../images/bambusheader.png';
import './../css/header.css';
import telefon from './../images/telefon.png';
import logo from './../images/logoBIG.png';

class Header extends Component {
    render() {
        return (
            <div>
            <div className="container-fluid" id="header">
            <div id="headertext">
            <img src={telefon} alt="phone" id="headerphone" className="mr-3"/>
            <a href="tel:04321-6907866">04321-690 78 66</a>
            </div>
            <img src={header} alt="header" id="bambus"/>

            <img src={logo} alt="logo" id="logo"/>
            <div id="background"></div>
        </div>
        
        </div>
        )
    }
}

export default Header;